
function Gallery() {
    return (
        <div className="mt-28 container flex items-center justify-center h-96">
            
        </div>
    )
}

export default Gallery
